import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	H4,
	Icon,
	IconApprove,
	InlineText,
	Paragraph,
	TextContainer,
	Tooltip,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { Radios } from "./radios";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Radios",
	notepad: "https://hackmd.io/bm_bntUqTYy9Fb5nOokLhw",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Radios"
			subTitle="Radios allow users to choose only one item from a list of mutually exclusive choices."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Radios} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<Code>Radios</Code> are always used in groups, and each option is represented by one{" "}
					<Code>Radios.Radio</Code> in the group.
				</Paragraph>
				<Ul
					items={[
						<>
							<H4>Default state:</H4>
							one option is selected in a radio group by default. If you click on another option,
							the current option will be deselected.
						</>,
						<>
							<H4>Disabled state:</H4>
							indicates that an option exists but is not available in a particular case. The
							disabled state can be used to indicate that an option may be available again at a
							later time. If possible, provide hint text or a visual clue to explain why the option
							is disabled to avoid user confusion.
						</>,
						<>
							<H4>Label with dynamic text:</H4>
							pay attention to how the labels change in size and how the visual elements surrounding
							them are affected.
						</>,
						<>
							<H4>Label with icon or tooltip:</H4>
							help the user understand the context of the option.
						</>,
					]}
				/>
				<Example fn={DefaultExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Radios}
					initialProps={({ setState }) => ({
						value: "option-1",
						onChange: (v) => setState({ value: v }),
						children: (
							<>
								<Radios.Radio value="option-1">Option 1</Radios.Radio>
								<Radios.Radio value="option-2">Option 2</Radios.Radio>
								<Radios.Radio value="option-3">Option 3</Radios.Radio>
							</>
						),
					})}
				/>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Radios</Code> when
					</Paragraph>
					<Ul
						items={[
							"the user is only allowed to select one option from a list of related items.",
							"the user must see all options and compare the available options before making a choice.",
							<>
								suggesting options for the user. By making suggestions in the form of{" "}
								<Code>Radios</Code> instead of text entry fields or other types of input, we make it
								easier for the user.
							</>,
						]}
					/>
					<Header.H4>Options</Header.H4>
					<Ul
						items={[
							"At least two options are required in a list. Limit the number of options to seven or fewer to reduce the cognitive load on users.",
							<>
								The options are mutually exclusive, i.e. only one option can be selected at a time.
								Each label should be both comprehensive and unambiguous. For example, "small, medium
								and large" are mutually exclusive. While "small, medium, large, medium/large" are
								not mutually exclusive.
							</>,
							"A logical order should be followed for the list of options, e.g. from most likely to least likely choice, from simplest to most complex, or from least to highest risk.",
							"Provide a default selection or a recommended radio selection to assist the user. A default selection helps in situations where the options are complex or unfamiliar to the user.",
							<>Add a “None” option when the user does not have an appropriate answer.</>,
							<>Add an “Other” option if the user has an answer that is not included in a list.</>,
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>Radios are typically used in the following places:</Paragraph>
					<Ul
						items={[
							<>
								<H4>Forms</H4>
								Radios can be used in cards, modals, side panels, and forms on full pages. They can
								often serve as a filtering mechanism. An example of a radio in a form can be found
								in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FIntegrations%2FShareBar%2FCreate%3Flang%3Den-US">
									Customise New Share Widget
								</a>
								.
							</>,
							<>
								<H4>Settings</H4>
								Radios can be used to create new settings or change settings in a menu or widget. An
								example of radios in settings can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSettings%2F41825%2FAnalyticsFeedback%2FCreateSurvey%3Flang%3Den-US">
									Create Survey
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							"Arrange the radios vertically to make it easier for users to compare options, especially in forms and settings. If only horizontal layouts are an option, arrange the radios and labels so that it is absolutely clear which option belongs to which label.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								the user can choose more than one item from a list. In this case, use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Checkbox">Checkbox</GatsbyLink>{" "}
								instead.
							</>,
							<>
								the user needs to toggle items on or off with an immediate effect. In this case, use
								a{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Toggle Switch">
									Toggle Switch
								</GatsbyLink>{" "}
								instead.
							</>,
							<>
								a list has more than seven options. In this case, use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink> instead.
							</>,
							<>
								the user can toggle between two or more alternate views of the same content. In this
								case, use a{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Toggle Group">
									Toggle Group
								</GatsbyLink>{" "}
								instead.{" "}
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								If possible, always provide a visible label for <Code>Radios</Code>. Alternatively,
								provide an <Code>aria-label</Code> to describe the purpose of <Code>Radios</Code>.
							</>,
							<>
								Use <Code>Radios</Code> instead of{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>{" "}
								components whenever possible. When you use radios, users who have difficulty making
								precise mouse movements can select an item more easily. When you use a select
								component, the user has to click on a button element to see a list of options or an
								action menu.
							</>,
							<>
								Allow users to select an item by clicking on its label to make it easier to select
								the item.
							</>,
							<>
								<Code>Radios</Code> should always be placed to the left of their labels. This makes
								Radios easier to find, especially for screen magnifier users.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Paragraph>
						Assistive technologies, like screen readers, require that each radio button and its
						surrounding group have a programmatically accessible label. This label can be provided
						through a visible <Code>&lt;label&gt;</Code>, <Code>aria-label</Code>,{" "}
						<Code>aria-labelledby</Code>, or similar attribute.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"It is recommended that the label contains three words or less.",
							"Use clear and concise labels.",
							"Use sentence case for a label.",
							"Do not include punctuation after a label.",
							<>
								Users cannot be assumed to know the number of options they can choose based on the
								visual difference between checkboxes and radios. Provide a hint text that indicates
								only one option should be selected from a list of radios. For example: “Select one
								option.”
							</>,
							<>
								If an answer is required, add a "(required)" text or an asterisk "(*)" after a group
								label.
							</>,
							<>
								Do not forget to include choices. The example below does not include 18.
								<Ul items={["Under 18", "Over 18"]} />
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Notable changes</Header.H2>
				<Header.H3>Migrating from FancyLab 16.x to FancyLab 17.x</Header.H3>
				<Paragraph>
					The <Code>Radios</Code> component on FancyLab 16.x or prior takes an array of{" "}
					<Code>control</Code> objects as input/property, which is terse and works well with
					typechecking, but it plays poorly with composition. As an example, adding a tooltip to a
					radio label is not possible using this structure as well as changing the icon position to
					after or before the label.
				</Paragraph>
				<Paragraph>
					So, in FancyLab 17.x, the <Code>Radios</Code> component was refactored to allow composing
					other components with them. Now, instead of having a controls object, subcomponents are
					used to create the controls.
				</Paragraph>
				<Paragraph>
					Please find below an example that illustrates the usage before and after these changes.
					Note that the <Code>controls</Code> property has been replaced by children of type{" "}
					<Code>Radios.Radio</Code>.
				</Paragraph>
				<Header.H4>Before</Header.H4>
				<CodeBlock language="tsx">
					{`
<Radios
	value={radioValue}
	onChange={setRadioValue}
	controls={[
		{
			value: "option-1",
			label: "Option 1",
		},
		{
			value: "option-2",
			label: "Option 2",
		},
		{
			value: "option-3",
			label: "Option 3",
		},
	]}
/>
`}
				</CodeBlock>
				<Header.H4>After</Header.H4>
				<CodeBlock language="tsx">
					{`
<Radios value={radioValue} onChange={setRadioValue}>
	<Radios.Radio value="option-1">Option 1</Radios.Radio>
	<Radios.Radio value="option-2">Option 2</Radios.Radio>
	<Radios.Radio value="option-3">Option 3</Radios.Radio>
</Radios>
`}
				</CodeBlock>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => {
	const [radioValue, setRadioValue] = useState("option-1");

	return (
		<Radios
			// Radios must be labeled via aria if no visible label is provided (i.e. via FormElementWrapper)
			aria-label="An example option group"
			value={radioValue}
			onChange={setRadioValue}
		>
			<Radios.Radio value="option-1">Regular option</Radios.Radio>
			<Radios.Radio value="option-2" disabled>
				Disabled option
			</Radios.Radio>
			<Radios.Radio value="option-3">
				<Icon>
					<IconApprove />
				</Icon>
				<InlineText>Option with left icon</InlineText>
			</Radios.Radio>
			<Radios.Radio value="option-4">
				{(props: { checked: boolean; disabled: boolean }) => (
					<>
						<InlineText
							emphasis={props.checked ? "medium" : "normal"}
							lineHeight="multi-line"
							tone={props.disabled ? "subtle" : undefined}
						>
							Option with right icon and dynamic text style
						</InlineText>
						<Icon>
							<IconApprove />
						</Icon>
					</>
				)}
			</Radios.Radio>
			<Tooltip variant={{ type: "interactive" }} content="Extra info on the option 5">
				<Radios.Radio value="option-5">Option with tooltip</Radios.Radio>
			</Tooltip>
		</Radios>
	);
};
